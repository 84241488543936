<template>
  <div>
    <v-list-item>
      <v-list-item-action>
        <i class="fa fa-link logo_icon" />
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>{{ mapUrl }}</v-list-item-title>
        <v-list-item-subtitle>Default Map Viewer</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" v-copy="mapUrl" icon>
                <i class="fa fa-copy" />
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :to="'/mapviewer/' + mapId" icon>
                <i class="fa fa-search" />
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </span>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-action />

      <v-list-item-content>
        <v-list-item-title> {{ mapUrl }}?itemsPerPage=5 </v-list-item-title>
        <v-list-item-subtitle
          >Map Viewer With Custom Marker Amount (itemsPerPage) Per Table
          Page</v-list-item-subtitle
        >
      </v-list-item-content>

      <v-list-item-action>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" v-copy="mapUrl + '?itemsPerPage=5'" icon>
                <i class="fa fa-copy" />
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="'/mapviewer/' + mapId + '?itemsPerPage=5'"
                icon
              >
                <i class="fa fa-search" />
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </span>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-action />

      <v-list-item-content>
        <v-list-item-title>{{ mapUrl }}?table=false</v-list-item-title>
        <v-list-item-subtitle>Map Viewer with No Table</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" v-copy="mapUrl + '?table=false'" icon>
                <i class="fa fa-copy" />
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="'/mapviewer/' + mapId + '?table=false'"
                icon
              >
                <i class="fa fa-search" />
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </span>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: "MapViewerLinks",
  props: {
    mapId: {
      type: Number,
      required: true
    },
    mapUrl: {
      type: String,
      required: true
    }
  }
};
</script>
