<template>
  <v-list-item>
    <v-list-item-action>
      <i class="fa fa-plus logo_icon" />
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>How to add to your own website</v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-dialog v-model="showInstructions">
        <template v-slot:activator="{ on: dialog }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn v-on="{ ...tooltip, ...dialog }" icon>
                <i class="fa fa-book" />
              </v-btn>
            </template>
            <span>View Documentation</span>
          </v-tooltip>
        </template>
        <v-tabs centered icons-and-text>
          <v-tab ripple
            ><span class="logo_text">Browser</span
            ><i class="fa fa-globe logo_icon"
          /></v-tab>
          <v-tab ripple
            ><span class="logo_text">Node</span><i class="fa fa-cubes logo_icon"
          /></v-tab>
          <v-tab ripple
            ><span class="logo_text">iFrame</span
            ><i class="fa fa-external-link logo_icon"
          /></v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Import the component by adding a src tag in the header
                  (&lt;head&gt;):<br />
                  <code>&lt;script src="https://unpkg.com/slider-map"&gt;</code>
                </p>

                <p>
                  Create elements in page:<br />
                  <code>&lt;div id="mapid"&gt;&lt;/div&gt;</code>
                  <br />
                  <code>&lt;div id="controlid"&gt;&lt;/div&gt;</code><br />
                  <small>You can set your own ids for the elements</small>
                </p>

                <p>
                  And then running in the script section:<br />
                  <code>&lt;script&gt;</code><br />
                  <code
                    >&nbsp;&nbsp;const map = new SliderMap("{{ mapId }}",
                    "mapid", "controlid");</code
                  ><br />
                  <code>&nbsp;&nbsp;map.create();</code><br />
                  <code>&lt;/script&gt;</code><br />
                  <small>If you are using different ids, pass them here</small>
                </p>

                <p>
                  <a href="https://jsfiddle.net/MapsForAll/v5atLrgc/"
                    ><i class="fa fa-jsfiddle" />JSFiddle Example</a
                  >
                </p>
              </v-card-text>
            </v-card></v-tab-item
          >
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Install the timeline map package using NPM<br />
                  <code>npm install --save slider-map</code>
                </p>

                <p>
                  Create elements in page:<br />
                  <code>&lt;div id="mapid"&gt;&lt;/div&gt;</code>
                  <br />
                  <code>&lt;div id="controlid"&gt;&lt;/div&gt;</code><br />
                  <small>You can set your own ids for the elements</small>
                </p>

                <p>
                  And then running:<br />
                  <code>import {SliderMap} from 'slider-map';</code><br />
                  <code
                    >const map = new SliderMap("{{ mapId }}", "mapid",
                    "controlid");</code
                  ><br />
                  <code>map.create();</code><br />
                  <small>If you are using different ids, pass them here</small>
                </p>
              </v-card-text>
            </v-card></v-tab-item
          >
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  You can embed the map viewer into your own page (you can set
                  the size of the frame to fit your website):<br />
                  <code
                    >&lt;iframe width="960" height="540"
                    style="position:relative; max-width:100%;" src="{{
                      mapUrl
                    }}"&gt;&lt;/iframe&gt;</code
                  >
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: "UsageInstructions",
  props: {
    mapId: {
      type: Number,
      required: true
    },
    mapUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showInstructions: false
    };
  }
};
</script>
