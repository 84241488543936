<template>
  <v-container>
    <transition name="fade" mode="out-in" appear>
      <div v-if="currMap && isPublished">
        <v-layout row>
          <v-flex xs12 sm6 offset-sm3>
            <v-card>
              <v-card-title class="white--text pl-5 pt-5">
                <h3 class="headline logo_text">
                  <img v-if="currMap.icon" :src="currMap.icon" height="42" />
                  <i v-else class="fa fa-map-o logo_icon">&nbsp;</i
                  >{{ currMap.title }}
                </h3>
              </v-card-title>

              <v-list two-line>
                <v-list-item>
                  <v-list-item-action>
                    <i class="fa fa-calendar logo_icon" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ currMap.publishedDate }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Last Published
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <publish-modal>
                      <v-tooltip slot-scope="{ openDialog }" top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :icon="true"
                            @click.stop="openDialog"
                            text
                          >
                            <i class="fa fa-share-square-o" />
                          </v-btn>
                        </template>
                        <span>Publish</span>
                      </v-tooltip>
                    </publish-modal>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset />

                <v-list-item v-if="viewCount != -1">
                  <v-list-item-action>
                    <i class="fa fa-calculator logo_icon" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ viewCount }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Published Map View Count
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action />
                </v-list-item>
                <v-divider inset />

                <map-viewer-links :map-id="currMap.id" :map-url="mapUrl" />

                <v-divider inset />

                <instructions :map-id="currMap.id" :map-url="mapUrl" />
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>

        <br />
        <div id="mapid" />
        <div id="controlid" />
      </div>
      <div v-else-if="currMap">
        <v-layout>
          <v-flex xs12 sm6 offset-sm3>
            <unpublished-card />
          </v-flex>
        </v-layout>
      </div>
    </transition>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { SliderMap } from "slider-map";
import instructions from "../components/Publish/UsageInstructions";
import mapViewerLinks from "../components/Publish/MapViewerLinks";
import unpublishedCard from "../components/Publish/UnpublishedCard";
const publishModal = () =>
  import(
    /* webpackChunkName: "publishModal" */ "../components/Map/PublishModal.vue"
  );

export default {
  name: "Publish",
  components: {
    instructions,
    mapViewerLinks,
    publishModal,
    unpublishedCard
  },
  data() {
    return {
      mapUrl: "",
      showInstructions: false,
      viewCount: -1
    };
  },
  computed: {
    ...mapGetters(["currMap"]),
    isPublished() {
      return this.currMap.publishedDate !== "nodate";
    }
  },
  watch: {
    currMap() {
      if (this.currMap) {
        this.$nextTick(() => {
          const map = new SliderMap(this.currMap.id, "mapid", "controlid");
          map.addListener("metaData", metaData => {
            this.viewCount = metaData.viewCount ? metaData.viewCount : -1;
          });
          map.create();
          this.mapUrl = `${window.location.protocol}//${window.location.hostname}/MapViewer/${this.currMap.id}`;
        });
      } else {
        this.mapUrl = "";
        this.viewCount = -1;
      }
    },
    $route() {
      this.$store.dispatch("getMapData", this.$route.params["title"]);
    }
  },
  mounted() {
    this.$store.dispatch("getMapData", this.$route.params["title"]);
  }
};
</script>
