<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <h3 class="headline logo_text">
          <img v-if="currMap.icon" :src="currMap.icon" height="42" />
          <i v-else class="fa fa-map-o logo_icon">&nbsp;</i>
          {{ currMap.title }}
        </h3>
      </div>
    </v-card-title>
    <v-card-text>
      <p>
        <v-alert v-if="currMap.markers.length == 0" type="info">
          To view your map in the map viewer you will need to add markers and
          then publish your map.
        </v-alert>
        <v-alert v-else type="info">
          Your map will need to be published to view markers and map data in the
          map viewer. This can be done by clicking on the
          <i class="fa fa-share-square-o" /> 'publish map' button below.
        </v-alert>
      </p>
    </v-card-text>
    <v-card-actions>
      <publish-modal v-if="currMap.markers.length > 0">
        <v-btn slot-scope="{ openDialog }" @click.stop="openDialog" text>
          <i class="fa fa-share-square-o" />
          <span class="hidden-md-and-down">&nbsp;Publish Map</span>
        </v-btn>
      </publish-modal>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

const publishModal = () =>
  import(
    /* webpackChunkName: "publishModal" */ "../../components/Map/PublishModal.vue"
  );

export default {
  name: "UnpublishedCard",
  components: {
    publishModal
  },
  computed: {
    ...mapGetters(["currMap"])
  }
};
</script>
